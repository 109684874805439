<template>
  <b-card
  >
    <LabelQr v-if="imageQr" :image-qr="imageQr" ticket_id="label-image"/>
    <b-modal
        hide-footer
        id="modal-prevent-closing-qr"
        ref="my-modal"
        :title="`Qr`"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <b-overlay
          :show="show2"
          no-wrap
      />
      <b-row>
        <b-col cols="12" lg="6">
          <div align="center">
            <img v-if="imageQr" :src="imageQr" alt="">
          </div>
          <div align="center">
            <b-button
                @click="printTicket"
                style="width: 25%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              imprimir
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div align="center" style="margin-top: 20px; font-size: 17px">
            Id Producto: <strong>{{ send.product_id }}</strong> <br><br>
            Lote: <strong>{{ send.lots }}</strong><br><br>
            Fecha de caducidad: <strong>{{ send.date_expiry }}</strong><br><br>
            Código de barras : <strong>{{ send.barcode ? send.barcode : '' }}</strong><br><br>
            Consecutivo: <strong>{{ send.consecutive ? send.consecutive : '' }}</strong><br><br>
          </div>

        </b-col>
      </b-row>

    </b-modal>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mb-3">
      <b-col
          lg="12"
      >
        <h2>Agregue una nueva etiqueta</h2>
      </b-col>
      <b-col
          lg="12"
      >
        <b-form @submit.prevent="sendLabel(send)">
          <b-row>
            <b-col
                lg="12"
            >
              <b-form-group
                  label="Producto"

              >
                <v-select
                    style=" border-radius: 1px !important;"
                    v-model="selectProduct"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    @input="searchByProduct()"
                    :options="optionsProducts"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                  label="Lote"
              >
                <b-form-input
                    v-model="send.lots"
                    placeholder="Lote"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                  label="Marca"
              >
                <b-form-input
                    v-model="send.brand"
                    placeholder="Marca"
                    :disabled="brandActive"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Código de barras"
              >
                <div style="display: flex">
                  <b-form-radio
                      v-model="checkBarcode"
                      @change="changeBarcode"
                      :value="true"
                  >
                    Si
                  </b-form-radio>
                  <b-form-radio
                      style="margin-left: 20px"
                      v-model="checkBarcode"
                      @change="send.barcode = null"
                      :value="false"
                  >
                    No
                  </b-form-radio>
                </div>

                <b-form-input
                    v-if="checkBarcode"
                    style="margin-top: 20px"
                    v-model="send.barcode"
                    placeholder="Código de barras"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
              <b-form-group
                  label="Fecha de caducidad"
              >
                <flat-pickr
                    v-model="send.date_expiry"
                    class="form-control"
                    :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                />
                <div v-if="send.date_expiry"
                     style="position: absolute; margin-top: -28px; right: 30px; z-index: 100; cursor: pointer"
                     @click="clearDate">
                  <strong>X</strong>
                </div>
              </b-form-group>
            </b-col>

            <b-col
                cols="12" lg="6"
            >
              <b-form-group
                  label="Numero de etiquetas"
                  label-for="Numero de etiquetas"
              >
                <b-form-input
                    @keypress="isNumber($event)"
                    v-model="numberLabel"
                    placeholder="Numero de etiquetas"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-button
                  style="width: 100%; margin-top:28px"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  @click="verifyConsecutive"
                  variant="primary"
                  class="mr-1"
              >
                Validar datos
              </b-button>
            </b-col>
            <b-col cols="12">
              <b-row v-if="listNumber" v-for="(a, idx) in listNumber" :key="idx">
                <b-col cols="12" lg="6">
                  <b-form-group
                      label="Consecutivo"
                  >
                    <b-form-input

                        disabled=""
                        v-model="a.value"
                        placeholder="Consecutivo"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                  <b-button
                      style="width: 50%; margin-top: 30px"
                      @click="generateQr(idx,a.value)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Ver Qr
                  </b-button>
                </b-col>
              </b-row>
            </b-col>


            <b-col cols="12" class="mt-1">
              <div align="right">
                <b-button
                    style="width: 25%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                >
                  Guardar
                </b-button>
              </div>


            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm, BOverlay,
  BFormRadio

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import {mapGetters, mapActions} from "vuex";
import {VueAutosuggest} from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import LabelQr from "../../components/LabelQr/LabelQr"
import jsPDF from "jspdf";

export default {
  name: "CreateLabel",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar, flatPickr, vSelect, VueAutosuggest, BOverlay, BFormRadio, LabelQr
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsProducts: [],
      numberLabel: null,
      itemProductsAll: [],
      selectProduct: {id: "", value: "Seleccione un producto"},
      show: false,
      brandActive: false,
      show2: false,
      imageQr: null,
      listNumber: null,
      checkBarcode: false,
      send: {
        lots: null,
        brand: null,
        date_expiry: null,
        position: null,
        lines: [],
        barcode: null,
        product_id: null,
      }

    }
  },

  async created() {
    await this.findProducts()
  },
  methods: {
    ...mapActions('pharmacy', ['createNewLabel', 'getConsecutive', 'generateQrNotLabel', 'getConsecutiveList']),
    ...mapActions('treatment', ['findProduct']),
    async changeBarcode() {
      this.send.consecutive = null
    },
    async generateQr(position, consecutive) {
      this.show = true;
      this.send.consecutive = consecutive;
      this.send.position = parseInt(position) + 1;
      const response = await this.generateQrNotLabel(this.send);
      if (response.code) {
        this.show = false
        this.imageQr = response.code;
        await this.$bvModal.show('modal-prevent-closing-qr');
      } else {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: 'error',
            variant: 'warning',
          },
        });
      }

    },
    async verifyConsecutive() {

      const responseVerify = await this.verifyData(true);
      if (responseVerify) {
        this.show = true
        const response = await this.getConsecutiveList({id: this.send.product_id, position: this.numberLabel});
        if (response.consecutive) {
          if (response.consecutive.length > 0) {
            let consecutiveNew = []
            for (const a of response.consecutive) {
              consecutiveNew.push({
                value: a
              })
            }
            this.listNumber = consecutiveNew
          }


        }
        this.show = false
      }
    },
    async searchByProduct() {
      if (this.send.product_id) {
        if (this.send.product_id !== this.selectProduct.id) {
          this.send.consecutive = null
        }
      }
      this.send.product_id = this.selectProduct ? this.selectProduct.id : null;
      const searchId = this.itemProductsAll.find((a) => a.id === this.send.product_id);
      if (searchId && searchId.brand) {
        this.send.brand = searchId.brand;
        this.brandActive = true;
      } else {
        this.send.brand = null;
        this.brandActive = false;
      }
    },
    async clearDate() {
      this.send.date_expiry = null;
    },
    async sendLabel(data) {
      this.send.position = this.numberLabel;
      this.send.lines = this.listNumber;
      const responseVerify = await this.verifyData();

      if (responseVerify) {
        this.show = true;

        const response = await this.createNewLabel(this.send);
        if (response.msg) {
          this.show = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'EditIcon',
              variant: 'success',
            },
          });
          this.checkBarcode = false;
          this.show = false;
          this.imageQr = null;
          this.selectProduct = {
            id: null,
            value: null
          }
          this.numberLabel = null
          this.listNumber = null
          this.send = {
            lots: null,
            product_id: null,
            date_expiry: null,
            brand: null,
            barcode: null,
            position: null,
            lines: [],
          }
        } else {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error,
              icon: 'error',
              variant: 'warning',
            },
          });
        }
      }
    },
    async verifyData(consecutiveNotVerify = false) {
      if (!this.send.product_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No ha seleccionado ningun producto',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.send.lots) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo lote requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.send.brand) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Marca requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.numberLabel) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo numero de etiquetas requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.send.date_expiry) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Fecha de caducidad requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!consecutiveNotVerify) {
        if (this.send.lines.length < 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campo Consecutivo requerido',
              icon: 'error',
              variant: 'warning',
            },
          });
          return false;
        }
      }

      if (this.checkBarcode) {
        if (!this.send.barcode) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campo Código de barras es requerido',
              icon: 'error',
              variant: 'warning',
            },
          });
          return false;
        }
      }
      return true
    },
    async findProducts() {
      const response = await this.findProduct();
      for (const a of response) {
        const de = a.default_code ? '(' + a.default_code + ')' : ''
        this.optionsProducts.push({
          id: a.id,
          value: a.name + ' ' + de
        })
        this.itemProductsAll.push({
          id: a.id,
          name: a.name,
          brand: a.default_code
        })
      }
    },
    async resetModal() {

    },
    async printTicket() {
      this.show2 = true
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      doc.setFontSize(10);
      const img = new Image()
      img.src = this.imageQr
      doc.addImage(img, 'png', 8, 1, 5, 5)
      doc.output('dataurlnewwindow');
      this.show2 = false
    //   this.show2 = false
    //   const label = document.getElementById('label-image').outerHTML;
    //   const print = window.open('', '');
    //   print.document.write(`
    //     <html lang="ES">
    //         <head>
    //             <title>ONKIMIA</title>
    //             <style>
    //               @page {
    //                 size: auto;
    //                 margin: 0;
    //               }
    //             </style>
    //         </head>
    //         <body>
    //            <div :id="ticket_id" class="ticket" style="width: 230px; height: auto; overflow: auto; padding-left: 10px">
    // <div style="font-family: sans-serif">
    //   <div style="text-align: center">
    //     <img  width="100" src="${this.imageQr}" alt="">
    //   </div>
    // </div>
    //         </body>
    //     </html>
    //     `);
    //   print.document.close();
    //   print.print();
    //   print.close();
    },
  }
}
</script>

<style scoped>

</style>